import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@ellucian/react-design-system/core/es/styles';
import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  TextField,
  Typography,
} from '@ellucian/react-design-system/core/es';
import {
  spacing30,
} from '@ellucian/react-design-system/core/es/styles/tokens';

const styles = theme => ({
  card: {
    maxWidth: '28.5rem',
    margin: 'auto',
    marginTop: '3.75rem',
    padding: spacing30,
  },
  formField: {
    marginTop: '1rem',
  },
  formButton: {
    float: 'right',
  },
});

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasLoginError: false,
    };
    if (props.hasLoginError) {
      this.state = {
        hasLoginError: true,
      };
    }
  }

  handleChangeLogin = event => {
    this.setState({ hasLoginError: false });
  };

  render() {
    const customId = 'login';
    const { classes } = this.props;
    const HelperTextArea = this.state.hasLoginError ? (
      <FormHelperText
        error={this.state.hasLoginError}
        filled={!this.state.hasLoginError}
      >
        <FormattedMessage id="dash.login.error" />
      </FormHelperText>
    ) : null;

    return (
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h1">
            <FormattedMessage id="dash.login.header" />
          </Typography>
          <form
            name="sign-in-form"
            onSubmit={this.handleSignIn}
            action="login"
            method="POST"
          >
            <FormControl id={`${customId}_Container`} component="fieldset">

              <TextField name="username"
                label={<FormattedMessage id="dash.login.label.username" />}
                className={classes.formField}
                autoComplete="username"
                fullWidth
              />
              <TextField name="password"
                label={<FormattedMessage id="dash.login.label.password" />}
                type="password"
                passwordToggle
                className={classes.formField}
                autoComplete="current-password"
                fullWidth
              />
              <div className={classes.formButton}>
                <Button type="submit" className={classes.formField}>
                  <FormattedMessage id="dash.login.button.signIn" />
                </Button>
              </div>
              <div>
                {HelperTextArea}
              </div>
            </FormControl>
          </form>
        </CardContent>
      </Card>
    );
  }
}

LoginForm.propTypes = {
  classes: PropTypes.object,
  hasLoginError: PropTypes.string,
};

export default withStyles(styles)(LoginForm);
