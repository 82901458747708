import React, { Component } from 'react';
import queryString from 'query-string';
import { FormattedMessage, IntlProvider } from 'react-intl';
import {
  EDSApplication,
  HeaderBar,
  HeaderBarLogo,
  Logo,
  Typography,
  Footer,
} from '@ellucian/react-design-system/core/es';
import { createEDSTheme } from '@ellucian/react-design-system/core/es/themes';
import { IconSprite } from '@ellucian/ds-icons/lib';
import LoginForm from './LoginForm';
import ctaColor from '@ellucian/react-design-system/core/themes/common/ctaColors';
import validUrl from 'valid-url';
import PropTypes from 'prop-types';
import { withStyles } from '@ellucian/react-design-system/core/es/styles';
import {
  spacing40,
} from '@ellucian/react-design-system/core/es/styles/tokens';
import { createBrowserHistory } from 'history';
import {
  Router,
  Switch,
  Route,
} from 'react-router-dom';
import PageLoader from '../dashboardApp/components/PageLoader';

// Configure router
const browserHistory = createBrowserHistory({ basename: window.BASE_URL });

const styles = {
  customLogo: {
    padding: '0.85rem',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  logo: {
    marginTop: '-1rem',
    width: `calc(5.625rem + ${spacing40} + ${spacing40})`,
    '@supports (padding-top: env(safe-area-inset-left))': {
      width: `calc(5.625rem + ${spacing40} + ${spacing40} + env(safe-area-inset-left))`,
    },
  },
};

const parsedQuery = queryString.parse(window.location.search);

class LoginApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      messages: null,
      settings: {},
      theme: createEDSTheme('light'),
      customLogoUrl: null,
      isThemeLoaded: false,
    };
  }

  componentDidMount() {
    fetch('api/messages/dash.login')
      .then(response => response.json())
      .then(messages => this.setState({ messages }));

    fetch('api/settings/theme')
      .then(response => {
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
      .then(settings => {
        this.setState({ settings }, this.configureTheme);
      })
      .catch(error => {
        console.log(`Load Settings error occurred: ${error}`);
      });

  }

  isColorValid(color) {
    const hexRegex = new RegExp(/^#([0-9a-f]{6}|[0-9a-f]{3})$/i);

    if (hexRegex.test(color)) {
      return true;
    } else if (!color) {
      return true;
    } else {
      return false;
    }
  }

  configureTheme = () => {
    const configuredOverrides = { palette: {} };
    const primaryColor = this.state.settings['theme.color.primary'];
    const secondaryColor = this.state.settings['theme.color.secondary'];
    const tertiaryName = this.state.settings['theme.color.tertiaryName'];
    const customLogoUrl = this.state.settings['theme.url.logo'];
    if (validUrl.isWebUri(customLogoUrl)) {
      this.setState({ customLogoUrl });
    }
    else {
      console.log('Ignoring invalid customLogoUrl: ' + customLogoUrl);
    }
    if (primaryColor && primaryColor.length > 0) {
      if (this.isColorValid(primaryColor)) {
        configuredOverrides.palette.primary = { main: primaryColor };
      }
      else {
        console.log('Ignoring invalid primaryColor: ' + primaryColor);
      }
    }
    if (secondaryColor && secondaryColor.length > 0) {
      if (this.isColorValid(secondaryColor)) {
        configuredOverrides.palette.secondary = { main: secondaryColor };
      }
      else {
        console.log('Ignoring invalid secondaryColor: ' + primaryColor);
      }
    }
    if (tertiaryName && tertiaryName.length > 0) {
      configuredOverrides.palette.ctaColorName = tertiaryName;
    }
    this.updateTheme(configuredOverrides);
  };

  updateTheme = (overrides) => {
    if (overrides === 'default') {
      this.setState({
        theme: createEDSTheme('light'),
      });
      return;
    }
    const configuredOverrides = { palette: {} };
    if (overrides.palette.primary) {
      configuredOverrides.palette.primary = { main: overrides.palette.primary.main };
    }
    if (overrides.palette.secondary) {
      configuredOverrides.palette.secondary = { main: overrides.palette.secondary.main };
    }
    if (overrides.palette.ctaColorName) {
      configuredOverrides.palette.ctaColor = { ...ctaColor[overrides.palette.ctaColorName.toLowerCase()] };
    }
    const newTheme = createEDSTheme('light', configuredOverrides);
    this.setState({ theme: newTheme, isThemeLoaded: true });
  };

  render() {
    const { classes } = this.props;
    let children;
    const messages = this.state.messages;

    if (messages && this.state.isThemeLoaded) {
      children = (
        <IntlProvider locale="en" messages={messages}>
          <EDSApplication locale="en"
            theme={this.state.theme}
            labelOverrides={{
              'component.Logo.logo': messages['dash.login.component.Logo.logo'],
              'component.TextField.passwordHide': messages['dash.login.component.TextField.passwordHide'],
              'component.TextField.passwordShow': messages['dash.login.component.TextField.passwordShow'],
              'component.TextField.showPassword': messages['dash.login.component.TextField.showPassword'],
              'component.TextField.hidePassword': messages['dash.login.component.TextField.hidePassword'],
              'component.TextField.toggleVisibility': messages['dash.login.component.TextField.toggleVisibility'],
            }}
          >
            <IconSprite />
            <HeaderBar position="fixed" id={'HeaderBar'}
              SearchTextFieldProps={{inputProps: {'aria-label': this.state.messages['dash.login.header']}}}
              logo={
                <HeaderBarLogo>
                  {this.state.customLogoUrl
                    ? (
                      <img src={this.state.customLogoUrl} className={classes.customLogo}
                        alt={this.state.messages['dash.navigation.degreeworks']}
                      />
                    )
                    : (
                      <Logo classes={{ root: classes.logo }} />
                    )}
                </HeaderBarLogo>
              }>
            </HeaderBar>
            <Router history={browserHistory}>
              <Switch>
                <Route exact path="/login.html">
                  <LoginForm hasLoginError={parsedQuery.error} />
                </Route>
                <Route exact path="/accessDenied.html">
                  <br /><br /><br />
                  <Typography align="center" variant="h1" gutterBottom>
                    <FormattedMessage id="dash.login.accessDenied.title" />
                  </Typography>
                  <Typography align="center" variant="body1">
                    <FormattedMessage id="dash.login.accessDenied.header" />
                  </Typography>
                  <Typography align="center" variant="body1">
                    <a href="./logout">
                      <FormattedMessage id="dash.login.accessDenied.login" />
                    </a>
                  </Typography>
                </Route>
                <Route exact path="/logout.html">
                  <br /><br /><br />
                  <Typography align="center" variant="h1" gutterBottom>
                    <FormattedMessage id="dash.login.logout.title" />
                  </Typography>
                  <Typography align="center" variant="body1">
                    <FormattedMessage id="dash.login.logout.header" />
                  </Typography>
                  <Typography align="center" variant="body1">
                    <a href=".">
                      <FormattedMessage id="dash.login.logout.login" />
                    </a>
                  </Typography>
                </Route>
              </Switch>
            </Router>
            <Footer footerText={this.state.messages['dash.login.footer']} />
          </EDSApplication>
        </IntlProvider>
      );
    } else {
      children = (<PageLoader />);
    }

    return <div>{children}</div>;
  }
}

LoginApp.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(LoginApp);
