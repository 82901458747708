import React, { useEffect, useState } from 'react';
import {
  CircularProgress,
} from '@ellucian/react-design-system/core/es';
import PropTypes from 'prop-types';

let timeoutId;

function PageLoader(props) {
  const [isRender, setIsRender] = useState(true);
  useEffect(() => {
    timeoutId = setTimeout(doReRender, 5000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const doReRender = () => {
    setIsRender(prev => !prev);
    clearTimeout(timeoutId);
    setIsRender(prev => !prev);
    timeoutId = setTimeout(doReRender, 10000);
  };

  return (
    <div>
      {isRender &&
        <CircularProgress role="alert"
          aria-live="assertive"
          aria-relevant="all"
          aria-label={props.label ? props.label : 'Loading'}
          className="loadingIndicator"
        />
      }
    </div>
  );
}

PageLoader.propTypes = {
  label: PropTypes.string,
};

export default PageLoader;
